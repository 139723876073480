import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import React, { useContext } from "react"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H3 from "../atoms/H3"
import H4 from "../atoms/H4"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"

type props = any

export default function ThreeTextColumnsBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const arr = [0, 0, 0]
  return (
    <div
      className={`flex justify-between lg:flex-row flex-col pt-6 md:pt-0`}
      style={{}}
      ref={animRef}
    >
      {arr.map((item, index) => {
        return (
          <div
            key={blockContent[`title${index + 1}`]}
            className={`lg:w-[360px] w-full lg:max-w-lg max-w-xl mx-auto ${
              index === 1 && "lg:mx-4 "
            } ${index !== 2 && "pb-20"} lg:pb-0`}
            style={{ ...fadeSlideIn.transition, ...textAnimation }}
          >
            <div className="lg:h-32 pb-4 lg:pb-0">
              <H3
                color={
                  thisPage
                    ? getCascadingColor(
                        cssVariables,
                        siteOverviewData,
                        thisPage,
                        blockContent
                      ).title
                    : ""
                }
                className={`overflow-y-visible font-title font-bold `}
              >
                {blockContent[`title${index + 1}`]}
              </H3>
              <H4
                color={
                  thisPage
                    ? getCascadingColor(
                        cssVariables,
                        siteOverviewData,
                        thisPage,
                        blockContent
                      ).title
                    : ""
                }
                className={`overflow-y-visible `}
              >
                {blockContent[`subtitle${index + 1}`]}
              </H4>
            </div>
            <TextBasic
              className={`h-auto lg:h-40 xl:h-52`}
              innerHtml={blockContent[`text${index + 1}`]}
              small
            ></TextBasic>
            {blockContent[`buttons${index + 1}`] &&
              blockContent[`buttons${index + 1}`].map(
                (button: any, index: number) => {
                  return <Button button={button}></Button>
                }
              )}
          </div>
        )
      })}
    </div>
  )
}

export const ThreeTextColumnsBlock_Inputs = {
  key: "threeTextColumnsBlock",
  name: "Three Text Columns",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Column 1",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title1",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle1",
        },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text1",
        },
        { type: "group", inputGroupName: "ctaInputGroup", identifier: "1" },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Column 2",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title2",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle2",
        },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text2",
        },
        { type: "group", inputGroupName: "ctaInputGroup", identifier: "2" },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Column 3",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title3",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle3",
        },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text3",
        },
        { type: "group", inputGroupName: "ctaInputGroup", identifier: "3" },
      ],
    },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
