import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import BloqmatterLink from "../atoms/BloqmatterLink"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"
import { DataContext } from "../layout"

type props = any

export default function TitleBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)

  const textAlignClass =
    blockContent.titleTextAlign === "start"
      ? "text-left"
      : blockContent.titleTextAlign === "center"
      ? "text-center"
      : "text-right"
  return (
    <div className="md:px-20 sm:px-10 px-6">
      <H1
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : "black"
        }
        className={`${textAlignClass} overflow-y-visible`}
      >
        {blockContent.titleText}
      </H1>
      <H2
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : "black"
        }
        className={`${textAlignClass} overflow-y-visible`}
      >
        {blockContent.subtitleText}
      </H2>
    </div>
  )
}
