import React, { useContext } from "react"
import { Link, Page } from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "../layout"
import BloqmatterLink from "../atoms/BloqmatterLink"
import TextBasic from "../atoms/TextBasic"
import useStore from "../../store/store"

export default function Footer() {
  const { thisPage, cssVariables, footerData, headerData } =
    useContext(DataContext)
  const setIsShowingCookiePopup = useStore(
    state => state.setIsShowingCookiePopup
  )
  return (
    <footer
      className="w-screen relative"
      style={{
        boxShadow: "inset 0 7px 9px -7px rgba(0,0,0,0.4)",
        backgroundColor: cssVariables.colors[footerData.footerBgColor],
        color: cssVariables.colors[footerData.footerTextColor],
      }}
    >
      <div className="flex justify-between max-w-[2000px] items-center mx-auto px-6">
        <div className="lg:flex hidden  justify-between items-center">
          {footerData.links?.map((item: any, index: number) => (
            <BloqmatterLink link={item.link} key={index}>
              <FooterLinkItem link={item.link} />
            </BloqmatterLink>
          ))}
        </div>
        <div className="flex flex-col lg:items-end items-start my-8">
          <TextBasic
            className="lg:text-right text-left "
            innerHtml={footerData.footerText}
          ></TextBasic>
          <div
            onClick={() => setIsShowingCookiePopup(true)}
            className="font-basic text-sm opacity-50 underline mt-2 cursor-pointer"
          >
            Cookie settings
          </div>
        </div>
      </div>
    </footer>
  )
}

function FooterLinkItem({ link }: { link: Link }) {
  const {
    thisPage,
    cssVariables,
    footerData,
    headerData,
    siteOverviewData,
    processedImageData,
  } = useContext(DataContext)
  return (
    <TextBasic
      className="mx-8  my-2 uppercase"
      small
      color={cssVariables.colors[footerData.textColor]}
    >
      {link.text}
    </TextBasic>
  )
}

export const addFooterInputs = [
  {
    inputType: "inputArray",
    keyName: "links",
    label: "Footer links:",
    itemName: "Link",
    childNameKey: "link",
    childInputs: [{ inputType: "link", keyName: "link", label: "" }],
  },
  {
    inputType: "color",
    keyName: "footerBgColor",
    label: "Footer Background Color",
  },
  {
    inputType: "color",
    keyName: "footerTextColor",
    label: "Footer Text Color",
  },
]
