import React, { ReactNode, useContext, useState } from "react"

import { gradientObjToCssString, Link } from "@rubendenbrok/bloqmatter-cms"
import BloqmatterLink from "./BloqmatterLink"
import TextBasic from "./TextBasic"
import { DataContext } from "../layout"

type elementProps = {
  className?: string
  button: { [x: string]: any }
  onClick: Function
}

export default function ButtonWithOnClick({
  button,
  className,
  onClick,
}: elementProps) {
  const { cssVariables } = useContext(DataContext)
  const [hover, setHover] = useState(false)
  return (
    <div
      className={`sm:mt-8 mt-6 relative w-fit block py-0.5 px-4 overflow-hidden rounded-2xl ${className}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div
        style={{
          // backgroundColor: cssVariables.colors[button?.buttonColor1],
          background: gradientObjToCssString(
            cssVariables.gradients[button.buttonGradient1]
          ),
        }}
        className={`absolute inset-0 w-full h-full`}
      ></div>
      <div
        style={{
          // backgroundColor: cssVariables.colors[button?.buttonColor1],
          background: gradientObjToCssString(
            cssVariables.gradients[button.buttonGradient2]
          ),
          opacity: hover ? 1 : 0,
          transition: "opacity 0.3s",
        }}
        className={`absolute inset-0 w-full h-full`}
      ></div>

      <TextBasic
        className="font-basic font-bold text-center relative z-50"
        color={cssVariables.colors[button?.buttonTextColor]}
      >
        {button?.buttonText}
      </TextBasic>
    </div>
  )
}
