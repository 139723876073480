import React, { createContext, useEffect } from "react"
import { ImageData, Page, VideoData } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor, {
  cssVariables,
  inputData,
  WebsiteColorData,
} from "../functions/getCascadingColor"
import BaiscTextBlock_Display from "./displayBlocks/BasicTextBlock_Display"
import DisplayBlock_Container from "./displayBlocks/DisplayBlock_Container"
import Imageblock_Display from "./displayBlocks/ImageBlock_Display"
import TitleBlock_Display from "./displayBlocks/TitleBlock_Display"
import VideoTitleBlock_Display from "./displayBlocks/VideoTitleBlock_Display"
import HeroVideoBlock_Display from "./displayBlocks/HeroVideoBlock_Display"
import Footer from "./molecules/Footer"
import Header from "./molecules/Header"
import Seo from "./seo"
import MediaTextBlock_Display from "./displayBlocks/MediaTextBlock_Display"
import ThreeTextColumnsBlock_Display from "./displayBlocks/ThreeTextColumnsBlock_Display"
import HeroTextBlock_Display from "./displayBlocks/HeroTextBlock_Display"
import QuoteBlock_Display from "./displayBlocks/QuoteBlock_Display"
import FormBlock_Display from "./displayBlocks/FormBlock_Display"
import ExplanationVideoBlock_Display from "./displayBlocks/ExplanationVideoBlock_Display"
import textSnippetsBlock_Display from "./displayBlocks/textSnippetsBlock_Display"
import CookieConsentPopup from "./organisms/CookieConsentPopup"

export interface WebsiteDataType {
  thisPage?: Page & inputData
  allPages: Page[]
  cssVariables: cssVariables
  footerData: { [x: string]: any }
  headerData: { [x: string]: any }
  processedImageData: ImageData[]
  videoData: VideoData[]
  seoData: { [x: string]: any }
  siteOverviewData: WebsiteColorData
  localeData: { [x: string]: any }
}

export interface LayoutProps extends WebsiteDataType {
  previewMode?: string
  hoveredBlockIndex?: number
  selectedBlockIndex?: number
  selectFunc?: Function
}

export const DataContext = createContext<WebsiteDataType>({
  allPages: [],
  cssVariables: { colors: [] },
  footerData: [],
  headerData: [],
  processedImageData: [],
  videoData: [],
  seoData: [],
  siteOverviewData: {
    colors_bg: 0,
    colors_text: 0,
    colors_title: 0,
  },
  localeData: {},
})

export default function Layout({
  previewMode,
  hoveredBlockIndex,
  selectedBlockIndex,
  selectFunc,
  thisPage,
  allPages,
  cssVariables,
  footerData,
  headerData,
  processedImageData,
  videoData,
  seoData,
  siteOverviewData,
  localeData,
}: LayoutProps) {
  const displayBlocks = {
    titleBlock: TitleBlock_Display,
    imageBlock: Imageblock_Display,
    videoTitleBlock: VideoTitleBlock_Display,
    basicTextBlock: BaiscTextBlock_Display,
    heroBlock: HeroVideoBlock_Display,
    mediaTextBlock: MediaTextBlock_Display,
    threeTextColumnsBlock: ThreeTextColumnsBlock_Display,
    heroTextBlock: HeroTextBlock_Display,
    quoteBlock: QuoteBlock_Display,
    formBlock: FormBlock_Display,
    explanationVideoBlock: ExplanationVideoBlock_Display,
    textSnippetsBlock: textSnippetsBlock_Display,
  }

  useEffect(() => {
    if (typeof window !== "undefined" && thisPage) {
      document.body.style.backgroundColor =
        cssVariables.colors[thisPage.pageOptions?.bgColor] ||
        cssVariables.colors[siteOverviewData.colors_bg]
    }
    return () => {}
  }, [])

  if (!thisPage) {
    return <></>
  }

  return (
    <DataContext.Provider
      value={{
        thisPage: thisPage,
        allPages: allPages,
        cssVariables: cssVariables,
        footerData: footerData,
        headerData: headerData,
        processedImageData: processedImageData,
        videoData: videoData,
        seoData: seoData,
        siteOverviewData: siteOverviewData,
        localeData: localeData,
      }}
    >
      <Seo />
      <CookieConsentPopup />
      <div
        id="layout"
        className={`w-full h-full relative transform-none overflow-x-hidden  ${
          previewMode === "mobile" ? "hidescrollbar" : ""
        }`}
        style={{
          minHeight: "100vh",
          backgroundColor: getCascadingColor(
            cssVariables,
            siteOverviewData,
            thisPage
          ).bg,
          color: getCascadingColor(cssVariables, siteOverviewData, thisPage)
            .text,
        }}
      >
        {thisPage.showHeader && <Header />}
        {thisPage.content.map((block, index) => {
          if (!(block.key in displayBlocks)) {
            return
          }
          const DisplayBlock =
            displayBlocks[block.key as keyof typeof displayBlocks]
          return (
            <DisplayBlock_Container
              key={index}
              index={index}
              blockContent={block.blockContent}
              hovered={hoveredBlockIndex === index}
              previewMode={previewMode}
              selectFunc={selectFunc}
            >
              {DisplayBlock && (
                <DisplayBlock blockContent={block.blockContent} />
              )}
            </DisplayBlock_Container>
          )
        })}
        {thisPage.showFooter && footerData && <Footer />}
      </div>
    </DataContext.Provider>
  )
}
