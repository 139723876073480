import React from "react"
import { useContext } from "react"
import { DataContext } from "../layout"
import { gradientObjToCssString } from "@rubendenbrok/bloqmatter-cms"

type GradientFadeProps = {
  bgGradient: number
  gradient1: number
  gradient2: number
  gradient3: number
}

export default function GradientFade({
  bgGradient,
  gradient1,
  gradient2,
  gradient3,
}: GradientFadeProps) {
  const { cssVariables } = useContext(DataContext)
  return (
    <div
      className="absolute w-full h-full left-0 top-0"
      style={{
        background: gradientObjToCssString(cssVariables.gradients[bgGradient]),
      }}
    >
      <div
        className="absolute w-full h-full left-0 top-0"
        style={{
          background: gradientObjToCssString(cssVariables.gradients[gradient1]),
          animation: "fadeInOut 4.5s alternate infinite",
        }}
      ></div>
      <div
        className="absolute w-full h-full left-0 top-0"
        style={{
          background: gradientObjToCssString(cssVariables.gradients[gradient2]),
          animation: "fadeInOut 8s alternate infinite",
        }}
      ></div>
    </div>
  )
}
