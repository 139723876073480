import React, { useContext } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import BloqmatterLink from "../atoms/BloqmatterLink"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"

import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"

type props = any

export default function VideoTitleBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData, videoData } =
    useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const ratio = videoObj?.metadata.height / videoObj?.metadata.width

  const textboxPositionStyle = getAbsolutePositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "20px"
  )

  const textAlignClass =
    blockContent.textHorizontalPos === "start"
      ? "text-left"
      : blockContent.textHorizontalPos === "center"
      ? "text-center"
      : "text-right"

  return (
    <div
      className="w-full relative"
      // style={{ paddingBottom: ratio * 100 + "%" }}
    >
      <div
        className="relative"
        style={{
          width: `min(100vw, ${videoObj?.metadata.width}px)`,
          height: `min(${videoObj?.metadata.height}px, calc(${ratio} * 100vw))`,
          maxHeight: videoObj?.metadata.height + "px",
          maxWidth: videoObj?.metadata.width + "px",
          marginLeft: blockContent.videoHorizontalPos === "start" ? "" : "auto",
          marginRight: blockContent.videoHorizontalPos === "end" ? "" : "auto",
        }}
      >
        <BgVideo videoId={blockContent.video} />
      </div>

      <div
        className={`absolute z-10 inline-block ${textAlignClass}`}
        style={{ ...textboxPositionStyle }}
      >
        {blockContent.title && (
          <H1
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`${textAlignClass} overflow-y-visible`}
          >
            {blockContent.title}
          </H1>
        )}
        {blockContent.subtitle && (
          <H2
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`text-${blockContent.textHorizontalPos} overflow-y-visible font-titleAccent md:mb-10`}
          >
            {blockContent.subtitle}
          </H2>
        )}
      </div>
    </div>
  )
}
