import React, { ReactNode } from "react"

type elementProps = {
  className: string
  color: string
  children?: ReactNode
  setInnerHtml?: string
  xl?: boolean
}

export default function H2({
  className,
  color,
  children,
  setInnerHtml,
  xl,
}: elementProps) {
  return (
    <h2
      style={{ color: color }}
      className={`${
        xl
          ? "md:text-8xl sm:text-6xl text-4xl font-basic font-semibold"
          : "md:text-5xl sm:text-4xl text-3xl font-title font-normal"
      }  m-0 select-none ${className}`}
      //dangerouslySetInnerHTML={setInnerHtml ? { __html: setInnerHtml } : null}
    >
      {children}
    </h2>
  )
}
