import { useEffect, useRef } from "react"

export default function useFrame(callBack: Function, depArr: any[]) {
  const frameRef = useRef<number>()

  function func() {
    callBack()
    frameRef.current = requestAnimationFrame(func)
  }

  useEffect(() => {
    frameRef.current = requestAnimationFrame(func)
    return () => {
      if (frameRef.current) cancelAnimationFrame(frameRef.current)
    }
  }, depArr)
}
