import React, { ReactNode } from "react"
import create from "zustand"

interface State {
  isShowingCookiePopup: boolean
  setIsShowingCookiePopup: (val: boolean) => void
}

const useStore = create<State>(set => ({
  isShowingCookiePopup: false,
  setIsShowingCookiePopup: val => set(state => ({ isShowingCookiePopup: val })),
}))

export default useStore
