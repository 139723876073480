import React, { LegacyRef, useContext, useRef } from "react"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import getCascadingColor from "../../functions/getCascadingColor"
import Button from "../atoms/Button"
import ButtonWithOnClick from "../atoms/ButtonWithOnClick"
import H2 from "../atoms/H2"
import H3 from "../atoms/H3"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"

type props = any

export default function FormBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const nameInputRef = useRef<HTMLInputElement>(null)
  const companyInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const questionInputRef = useRef<HTMLInputElement>(null)

  const textAlignClass =
    blockContent.textAlign === "start"
      ? "text-left"
      : blockContent.textAlign === "center"
      ? "text-center"
      : "text-right"

  function handleSubmit() {}

  return (
    <>
      <H2
        xl={blockContent.xlTitle}
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : ""
        }
        className={`${textAlignClass} overflow-y-visible $`}
      >
        {blockContent.title}
      </H2>
      {blockContent.title2 && (
        <H2
          xl={blockContent.xlTitle}
          color={
            thisPage
              ? getCascadingColor(
                  cssVariables,
                  siteOverviewData,
                  thisPage,
                  blockContent
                ).title
              : ""
          }
          className={`${textAlignClass} overflow-y-visible  mt-4`}
        >
          {blockContent.title2}
        </H2>
      )}
      {blockContent.subtitle && (
        <H3
          color={
            thisPage
              ? getCascadingColor(
                  cssVariables,
                  siteOverviewData,
                  thisPage,
                  blockContent
                ).title
              : ""
          }
          className={`${textAlignClass} overflow-y-visible font-titleAccent mt-4`}
        >
          {blockContent.subtitle}
        </H3>
      )}
      <div className="xl:flex" id="contact">
        <div className="w-full xl:w-1/2">
          <TextBasic
            className={`${textAlignClass} pt-8 max-w-xl`}
            innerHtml={blockContent.text}
          ></TextBasic>
        </div>
        <div className="md:flex xl:w-1/2 w-full justify-start xl:mt-0 mt-12">
          <div className="w-full max-w-md md:w-1/2">
            <Input
              label={blockContent.nameText}
              placeholder={""}
              id="name"
              ref={nameInputRef}
            />
            <Input
              label={blockContent.companyText}
              placeholder={""}
              id="company"
              ref={companyInputRef}
            />
            <Input
              label={blockContent.phoneText}
              placeholder={""}
              id="phone"
              type="tel"
              ref={phoneInputRef}
            />
          </div>
          <div className="md:ml-8 w-full  md:w-1/2">
            <Input
              label={blockContent.emailText}
              placeholder={""}
              id="email"
              type="email"
              ref={emailInputRef}
            />
            <label htmlFor="question">
              <b>{blockContent.questionText}</b>
            </label>
            <textarea
              id={"question"}
              className="px-2 py-0.5 resize-none w-full max-w-md"
              rows={8}
            ></textarea>
          </div>
        </div>
      </div>
      <ButtonWithOnClick
        className="xl:ml-auto block"
        button={{
          buttonText: blockContent.buttonText,
          buttonTextColor: blockContent.buttonTextColor,
          buttonGradient1: blockContent.buttonGradient1,
          buttonGradient2: blockContent.buttonGradient2,
        }}
        onClick={handleSubmit}
      ></ButtonWithOnClick>
    </>
  )
}

type InputProps = {
  ref: LegacyRef<HTMLInputElement> | null
  label: string
  placeholder: string
  id: string
  type?: string
  [x: string]: any
}

function Input({
  ref,
  label,
  placeholder,
  id,
  type = "text",
  ...rest
}: InputProps) {
  return (
    <>
      <label className="" htmlFor={id}>
        <b>{label}</b>
      </label>
      <input
        className="px-2 py-0.5 w-full max-w-md"
        type={type}
        id={id}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      ></input>
    </>
  )
}

export const FormBlock_Inputs = {
  key: "formBlock",
  name: "Contact Form Block",
  description: "A simple contact form",
  tags: ["form"],
  defaults: BLOCKOPTIONS_DEFAULTS,
  inputs: [
    { type: "group", inputGroupName: "titleInputGroup" },
    { inputType: "checkbox", keyName: "xlTitle", label: "XL Title" },
    { type: "group", inputGroupName: "shadowInputGroup" },
    {
      inputType: "richText",
      label: "Text",
      keyName: "text",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align",
      keyName: "textAlign",
      defaultVal: "center",
    },
    {
      inputType: "expandableInputs",
      label: "Form labels",
      childInputs: [
        {
          inputType: "text",
          label: "Name text",
          keyName: "nameText",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Company text",
          keyName: "companyText",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Phone text",
          keyName: "phoneText",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Email text",
          keyName: "emailText",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Question text",
          keyName: "questionText",
          defaultVal: "",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Button",
      childInputs: [
        {
          inputType: "text",
          label: "Submit button text",
          keyName: "buttonText",
          defaultVal: "",
        },
        {
          inputType: "color",
          label: "Text color",
          keyName: "buttonTextColor",
        },
        {
          inputType: "gradient",
          label: "Base background",
          keyName: "buttonGradient1",
        },
        {
          inputType: "gradient",
          label: "Hover background",
          keyName: "buttonGradient2",
        },
      ],
    },
  ],
}
