import React, { ReactNode } from "react"

type elementProps = {
  className: string
  color: string
  children?: ReactNode
  setInnerHtml?: string
}

export default function H1({
  className,
  color,
  children,
  setInnerHtml,
}: elementProps) {
  return (
    <h1
      style={{ color: color }}
      className={`font-titleAccent xl:text-8,5xl lg:text-8xl md:text-7xl sm:text-5xl text-4xl  font-normal m-0 -mt-4 select-none ${className}`}
      //dangerouslySetInnerHTML={setInnerHtml ? { __html: setInnerHtml } : null}
    >
      {children}
    </h1>
  )
}
