import React, { useContext } from "react"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import { DataContext } from "../layout"

type props = any

export default function QuoteBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)

  return (
    <div
      className={`md:px-20 sm:px-10 px-6 text-center text-xl sm:text-3xl ${
        blockContent.italic && "italic"
      } ${
        blockContent.bold && "font-bold"
      } font-basic leading-relaxed tracking-wide`}
    >
      {blockContent.text}
    </div>
  )
}
export const quoteBlock_Inputs = {
  key: "quoteBlock",
  name: "Quote Block",
  description: "A Block to display a quote",
  tags: ["Text"],
  inputs: [
    {
      inputType: "text",
      label: "Text",
      keyName: "text",
      defaultVal: "",
    },
    {
      inputType: "checkbox",
      label: "Italic",
      default: true,
      keyName: "italic",
    },
    {
      inputType: "checkbox",
      label: "Bold",
      default: false,
      keyName: "bold",
    },
  ],
  defauts: BLOCKOPTIONS_DEFAULTS,
}
