import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import React, { useContext } from "react"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import H3 from "../atoms/H3"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"
import InteractiveShadow from "../molecules/InteractiveShadow"

type props = any

export default function BaiscTextBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const mobileTextAlignClass =
    blockContent.textAlignMobile === "end"
      ? "text-right"
      : blockContent.textAlignMobile === "start"
      ? "text-left"
      : "text-center"

  const textAlignClass =
    blockContent.textAlign === "start"
      ? `sm:text-left ${mobileTextAlignClass}`
      : blockContent.textAlign === "center"
      ? `sm:text-center ${mobileTextAlignClass}`
      : `sm:text-right ${mobileTextAlignClass}`

  const mobileButtonAlignClass =
    blockContent.textAlignMobile === "start" || !blockContent.textAlignMobile
      ? "self-start"
      : blockContent.textAlignMobile === "center"
      ? "self-center"
      : "self-end"

  const buttonAlignClass =
    blockContent.textAlign === "start"
      ? `sm:self-start ${mobileButtonAlignClass}`
      : blockContent.textAlign === "center"
      ? `sm:self-center ${mobileButtonAlignClass}`
      : `sm:self-end ${mobileButtonAlignClass}`

  const maxWClass =
    blockContent.textBoxSize === "small" || !blockContent.textBoxSize
      ? "max-w-full sm:max-w-xl"
      : blockContent.textBoxSize === "medium"
      ? "max-w-full sm:max-w-2xl"
      : "max-w-full sm:max-w-3xl"

  return (
    <>
      {/* //DESKTOP VERSION */}
      <div
        ref={animRef}
        className={`max-w-full flex-col flex  ${
          blockContent.textBoxPosition === "start" ||
          !blockContent.textBoxPosition
            ? "items-start"
            : blockContent.textBoxPosition === "center"
            ? "items-center"
            : "items-end"
        }`}
        style={{
          marginLeft: blockContent.textAlign === "start" ? 0 : "auto",
          marginRight: blockContent.textAlign === "end" ? 0 : "auto",
          ...fadeSlideIn.transition,
          ...textAnimation,
        }}
      >
        <div className="inline-flex flex-col">
          <InteractiveShadow
            enabled={blockContent.interactiveShadow}
            baseHeight={blockContent.baseHeight / 50}
            wobbleAmt={blockContent.wobbleAmt / 100}
          >
            <H2
              xl={blockContent.xlTitle}
              color={
                thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).title
                  : ""
              }
              className={`${textAlignClass} overflow-y-visible  `}
            >
              {blockContent.title}
            </H2>
            {blockContent.title2 && (
              <H2
                xl={blockContent.xlTitle}
                color={
                  thisPage
                    ? getCascadingColor(
                        cssVariables,
                        siteOverviewData,
                        thisPage,
                        blockContent
                      ).title
                    : ""
                }
                className={`${textAlignClass} overflow-y-visible  mt-4`}
              >
                {blockContent.title2}
              </H2>
            )}
            {blockContent.subtitle && (
              <H3
                color={
                  thisPage
                    ? getCascadingColor(
                        cssVariables,
                        siteOverviewData,
                        thisPage,
                        blockContent
                      ).title
                    : ""
                }
                className={`${textAlignClass} overflow-y-visible font-titleAccent mt-4`}
              >
                {blockContent.subtitle}
              </H3>
            )}
          </InteractiveShadow>
          <TextBasic
            className={`${textAlignClass} pt-4 ${maxWClass}`}
            innerHtml={blockContent.text}
          ></TextBasic>
          <div className={`inline-block ${buttonAlignClass}`}>
            {blockContent.buttons &&
              blockContent.buttons.map((button: any, index: number) => {
                return <Button button={button}></Button>
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export const BasicTextBlock_Inputs = {
  key: "basicTextBlock",
  name: "Basic Text Block",
  inputs: [
    { type: "group", inputGroupName: "titleInputGroup" },
    { inputType: "checkbox", keyName: "xlTitle", label: "XL Title" },
    { type: "group", inputGroupName: "shadowInputGroup" },
    {
      inputType: "richText",
      label: "Text",
      keyName: "text",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align",
      keyName: "textAlign",
      defaultVal: "center",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align mobile",
      keyName: "textAlignMobile",
      defaultVal: "center",
    },
    {
      inputType: "radio",
      labelArr: ["Small", "Medium", "Full"],
      valueArr: ["small", "medium", "full"],
      label: "Text box size",
      keyName: "textBoxSize",
      defaultVal: "small",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text box position",
      keyName: "textBoxPosition",
      defaultVal: "start",
    },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
