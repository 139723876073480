import React, { useContext, useState, useEffect } from "react"
import useStore from "../../store/store"
import H4 from "../atoms/H4"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

export default function CookieConsentPopup() {
  const isShowingCookiePopup = useStore(state => state.isShowingCookiePopup)
  const setIsShowingCookiePopup = useStore(
    state => state.setIsShowingCookiePopup
  )
  const location = useLocation()

  const { thisPage, seoData, cssVariables } = useContext(DataContext)

  useEffect(() => {
    if (
      !getCookie("hasAskedCookieConsent") &&
      getCookie("gatsby-plugin-google-analytics-gdpr_cookies-enabled") !==
        "true"
    ) {
      setIsShowingCookiePopup(true)
    }
    return () => {}
  }, [])

  function onAccept() {
    if (typeof window !== "undefined") {
      setCookie("gatsby-gdpr-google-analytics", true, 365)
      setCookie("hasAskedCookieConsent", true, 365)
      setIsShowingCookiePopup(false)
      initializeAndTrack(location)
    }
  }

  function onReject() {
    if (typeof window !== "undefined") {
      setCookie("gatsby-gdpr-google-analytics", false, 10)
      setCookie("hasAskedCookieConsent", true, 10)
      setIsShowingCookiePopup(false)

      initializeAndTrack(location)
    }
  }

  return isShowingCookiePopup ? (
    <div
      className="w-screen fixed left-0 top-0 sm:top-[auto] sm:bottom-0 justify-center flex items-center flex-wrap py-4 fadeInSlow"
      style={{
        backgroundColor: cssVariables.colors[seoData.cookieBgColor],
        zIndex: 9999,
        boxShadow: "0 -2px 6px #00000055, 0 2px 6px #00000055",
      }}
    >
      <div className="min-w-[320px] max-w-[600px] px-8">
        <H4 color={seoData.cookieTextColor}>
          <b>{seoData.cookieTitle}</b>
        </H4>
        <TextBasic small color={seoData.cookieTextColor}>
          {seoData.cookieText}
        </TextBasic>
      </div>
      <div className="flex flex-wrap justify-start px-8 mt-6 sm:mt-0">
        <button
          onClick={onAccept}
          className="w-32 text-center py-2 rounded-full mr-4 my-2"
          style={{
            backgroundColor: cssVariables.colors[seoData.cookieAccentColor],
            color: cssVariables.colors[seoData.cookieBgColor],
          }}
        >
          <TextBasic>{seoData.acceptText}</TextBasic>
        </button>
        <button
          onClick={onReject}
          style={{
            border: `1px solid ${
              cssVariables.colors[seoData.cookieAccentColor]
            }`,
            color: cssVariables.colors[seoData.cookieAccentColor],
          }}
          className="w-32 text-center py-2 my-2 rounded-full"
        >
          <TextBasic>{seoData.rejectText}</TextBasic>
        </button>
      </div>
    </div>
  ) : (
    <></>
  )
}

export function setCookie(cName: string, cValue: any, exDays: number) {
  if (typeof window !== "undefined") {
    const d = new Date()
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cName + "=" + cValue + ";" + expires + ";path=/"
  }
}

export function getCookie(cName: string) {
  if (typeof window !== "undefined") {
    let name = cName + "="
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }
}
