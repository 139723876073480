import { SingleInputProps } from "@rubendenbrok/bloqmatter-cms"
import { Block } from "@rubendenbrok/bloqmatter-cms/lib/inputs/BlockIndex"
import { InputProps } from "@rubendenbrok/bloqmatter-cms/lib/inputs/single_inputs/SingleInput"
import React, { useContext } from "react"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import H3 from "../atoms/H3"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"
import InteractiveShadow from "../molecules/InteractiveShadow"

type props = any

export default function textSnippetsBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  return (
    <div
      ref={animRef}
      className={`w-full flex flex-wrap`}
      style={{
        ...fadeSlideIn.transition,
        ...textAnimation,
      }}
    >
      {blockContent.snippets.map((snippet: any, index: number) => {
        return <Snippet snippet={snippet} key={index} />
      })}
    </div>
  )
}

type SnippetProps = {
  [x: string]: any
}

function Snippet({ snippet }: SnippetProps) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)

  return (
    <div className="md:pr-16 lg:w-1/2 lg:pr-20 mb-16">
      <H3
        className={"font-title"}
        color={cssVariables.colors[snippet.titleColor]}
      >
        {snippet.title}
      </H3>
      <TextBasic className={` pt-2 `} innerHtml={snippet.text}></TextBasic>

      {snippet.buttons &&
        snippet.buttons.map((button: any, index: number) => {
          return <Button button={button}></Button>
        })}
    </div>
  )
}

export const textSnippetsBlock_Inputs: Block = {
  key: "textSnippetsBlock",
  name: "Text Snippets Block",
  description:
    "A block displaying two columns with snippets of text with a title and optional button. On mobile the user can tap the title to expand the text below.",
  tags: ["Text"],
  inputs: [
    {
      inputType: "inputArray",
      keyName: "snippets",
      label: "Snippets",
      childInputs: [
        { inputType: "text", keyName: "title", label: "Title" },
        { inputType: "color", keyName: "titleColor", label: "Title color" },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text",
        },
        { type: "group", inputGroupName: "ctaInputGroup" },
      ],
      childNameKey: "title",
      itemName: "Snippet",
    },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
