import React, { useContext } from "react"
import { Page } from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "../layout"
import DesktopHeader from "../organisms/DesktopHeader"
import MobileHeader from "../organisms/MobileHeader"

export default function Header() {
  return (
    <>
      <DesktopHeader />
      <MobileHeader />
    </>
  )
}
