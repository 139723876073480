import React, { useContext } from "react"
import {
  DEFAULT_BLOCKOPTIONS_DEFAULTS,
  getItemFromId,
} from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import BloqmatterLink from "../atoms/BloqmatterLink"
import H1 from "../atoms/H1"
import H2 from "../atoms/H2"

import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"

type props = any

export default function HeroVideoBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData, videoData } =
    useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const ratio = videoObj?.metadata.height / videoObj?.metadata.width

  const textboxPositionStyle = getAbsolutePositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "80px"
  )

  const textAlignClass =
    blockContent.textHorizontalPos === "start"
      ? "text-left"
      : blockContent.textHorizontalPos === "center"
      ? "text-center"
      : "text-right"

  return (
    <div
      className="w-full relative"
      // style={{ paddingBottom: ratio * 100 + "%" }}
    >
      <div
        className="relative grayscale filter mx-auto overflow-hidden"
        style={{
          width: `min(${cssVariables.maxContentWidth}, ${videoObj?.metadata.width}px, 100vw)`,
          height: `min(${videoObj?.metadata.height}px, calc(${ratio} * ${cssVariables.maxContentWidth}), calc(${ratio} * 100vw))`,
          maxHeight: videoObj?.metadata.height + "px",
          maxWidth: videoObj?.metadata.width + "px",
          marginLeft: blockContent.videoHorizontalPos === "start" ? "" : "auto",
          marginRight: blockContent.videoHorizontalPos === "end" ? "" : "auto",
        }}
      >
        <BgVideo videoId={blockContent.video} />
      </div>

      <div
        className={`absolute inline-block ${textAlignClass}   pb-48`}
        style={{ ...textboxPositionStyle }}
      >
        {blockContent.title && (
          <h1
            className={`min-h-[100px] xl:text-8,5xl lg:text-8xl md:text-7xl sm:text-5xl text-3xl font-titleAccent ${textAlignClass}`}
            style={{
              color: thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black",
            }}
          >
            {blockContent.title}
          </h1>
        )}
        {blockContent.subtitle && (
          <H2
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`text-${blockContent.textHorizontalPos} overflow-y-visible font-titleAccent mt-2`}
          >
            {blockContent.subtitle}
          </H2>
        )}
      </div>
    </div>
  )
}

export const HeroVideoBlock_Inputs = {
  key: "heroBlock",
  name: "Hero Video Block",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Title Horizontal Position",
          keyName: "textHorizontalPos",
          defaultVal: "left",
        },
        {
          inputType: "radio",
          labelArr: ["Top", "Center", "Bottom"],
          valueArr: ["start", "center", "end"],
          label: "Title Vertical Position",
          keyName: "textVerticalPos",
          defaultVal: "center",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Video",
      childInputs: [
        {
          inputType: "video",
          label: "Video",
          keyName: "video",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Video Horizontal Position",
          keyName: "videoHorizontalPos",
          defaultVal: "left",
        },
      ],
    },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
