import React, { ReactNode, useContext } from "react"
import generateBorderClipPath from "../../functions/generateBorderClippath"
import getCascadingColor from "../../functions/getCascadingColor"
import { getVisibilityFromBlockContent } from "../../styles/util"
import ShaderCanvas from "../glsl/ShaderCanvas"
import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import ResponsiveBgImg from "../molecules/ResponsiveBgImg"
import { gradientObjToCssString } from "@rubendenbrok/bloqmatter-cms"
import GradientFade from "../atoms/GradientFade"

type props = {
  blockContent: any
  index: number
  children: ReactNode
  hovered?: boolean
  selected?: boolean
  previewMode?: string
  selectFunc?: Function
}

const paddingFactor = 3
const borderFactor = 3

export default function DisplayBlock_Container({
  blockContent,
  children,
  hovered,
  selected,
  index,
  previewMode,
  selectFunc,
}: props) {
  const {
    allPages,
    thisPage,
    cssVariables,
    processedImageData,
    videoData,
    siteOverviewData,
  } = useContext(DataContext)
  const upperNeighbourBorderSize =
    index > 0 && thisPage
      ? thisPage.content[index - 1].blockContent.spacing_border_type
        ? thisPage.content[index - 1].blockContent.spacing_border_size *
          borderFactor
        : 0
      : 0

  const myBorderSize = blockContent.spacing_border_type
    ? blockContent.spacing_border_size * borderFactor
    : 0

  const positivePaddingTop =
    Math.max(blockContent.spacing_paddingTop, 0) * paddingFactor
  const positivePaddingBottom =
    Math.max(blockContent.spacing_paddingBottom, 0) * paddingFactor
  const negativePaddingBottom =
    Math.min(blockContent.spacing_paddingBottom, 0) * paddingFactor

  const visibilityClass = getVisibilityFromBlockContent(blockContent)

  return (
    <section
      onDoubleClick={() => {
        if (selectFunc) selectFunc(index)
      }}
      className={`w-full relative overflow-y-visible ${visibilityClass} `}
      style={{
        marginBottom: -myBorderSize + negativePaddingBottom + "px",
        zIndex: thisPage && thisPage.content.length - index,
      }}
    >
      <div
        className="mx-auto relative z-10 overflow-y-visible px-6 sm:px-12"
        style={{
          paddingTop: positivePaddingTop + "px",
          paddingBottom: positivePaddingBottom + myBorderSize + "px",
          marginBottom: negativePaddingBottom + "px",
          maxWidth: "1400px",
          color: thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).text
            : "",
        }}
      >
        {children}
      </div>

      <div
        className="w-full absolute inset-0 z-0"
        style={{
          height: `calc(100% + ${negativePaddingBottom}px)`,

          background:
            blockContent.bg_type === "color" && thisPage
              ? getCascadingColor(
                  cssVariables,
                  siteOverviewData,
                  thisPage,
                  blockContent
                ).bg
              : blockContent.bg_type === "gradient"
              ? gradientObjToCssString(
                  cssVariables.gradients?.[blockContent.bg_gradient]
                )
              : "",
          clipPath: blockContent.spacing_border_type
            ? generateBorderClipPath(
                blockContent.spacing_border_type,
                myBorderSize
              )
            : "none",
        }}
      >
        {blockContent.bg_type === "image" && blockContent.bg_image && (
          <ResponsiveBgImg
            image={blockContent.bg_image}
            imageCrop
            fx={blockContent.bg_scrollFx}
            repeat={blockContent.bg_repeat}
          />
        )}
        {blockContent.bg_type === "video" && blockContent.bg_video && (
          <BgVideo videoId={blockContent.bg_video} />
        )}
        {blockContent.bg_type === "gradientFade" && (
          <GradientFade
            bgGradient={blockContent.bg_gradient}
            gradient1={blockContent.bg_gradient1}
            gradient2={blockContent.bg_gradient2}
            gradient3={blockContent.bg_gradient3}
          />
        )}
        {/* {blockContent.bg_type === "shader" && <ShaderCanvas />} */}
      </div>

      {previewMode && (
        <div
          className="absolute w-full h-full left-0 top-0 pointer-events-none z-50"
          style={{
            boxShadow: hovered ? "inset 0 0 0px 4px red" : "none",
          }}
        ></div>
      )}
    </section>
  )
}
