import React, { useContext } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import BloqmatterLink from "../atoms/BloqmatterLink"
import H3 from "../atoms/H3"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"

import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import Button from "../atoms/Button"
import ResponsiveImg from "../molecules/ResponsiveImg"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"

type props = any

export default function MediaTextBlock_Display({ blockContent }: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const imgObj = getItemFromId(blockContent.image, processedImageData)

  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const ratio =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH / imgObj?.originalW
      : videoObj?.metadata.height / videoObj?.metadata.width

  const mediaWidth =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalW
      : videoObj?.metadata.width

  const mediaHeight =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH
      : videoObj?.metadata.height

  const textboxPositionStyle = getAbsolutePositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "80px"
  )

  const textAlignClass =
    blockContent.textAlign === "start"
      ? "sm:text-left text-center"
      : blockContent.textAlign === "center"
      ? "text-center"
      : "sm:text-right text-center"

  return (
    <div
      className={`w-full relative flex flex-col justify-center items-center ${
        blockContent.switchOrder ? "lg:flex-row-reverse" : "lg:flex-row"
      }`}
    >
      <div
        className={`relative flex-shrink-0 ${
          blockContent.showMediaOnMobile ? "block" : "hidden lg:block"
        } ${
          blockContent.mediaFormat === "image"
            ? blockContent.switchOrder
              ? "md:pr-20 sm:pl-0 sm:pr-10 px-6"
              : "md:pl-20 sm:pr-0 sm:pl-10 px-6"
            : ""
        }`}
        style={{
          width: `min(max(50vw, 400px), ${mediaWidth}px)`,
          height: `min(${mediaHeight}px, calc(${ratio} * max(50vw, 400px)))`,
          maxHeight: mediaHeight + "px",
          maxWidth: ``,
        }}
      >
        {blockContent.mediaFormat === "image" ? (
          <ResponsiveImg image={blockContent.image} />
        ) : (
          <BgVideo videoId={blockContent.video} />
        )}
      </div>

      <div
        ref={animRef}
        className={`block w-full ${textAlignClass} md:px-20 px-6 ${
          blockContent.showMediaOnMobile ? "lg:mt-0 mt-6" : ""
        }`}
        style={{
          ...textboxPositionStyle,
          ...fadeSlideIn.transition,
          ...textAnimation,
        }}
      >
        {blockContent.title && (
          <H2
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`${textAlignClass} overflow-y-visible  font-titleAccent `}
          >
            {blockContent.title}
          </H2>
        )}
        {blockContent.subtitle && (
          <H3
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`${textAlignClass}  overflow-y-visible font-titleAccent md:text-lg`}
          >
            {blockContent.subtitle}
          </H3>
        )}
        {blockContent.text && (
          <TextBasic
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).text
                : "black"
            }
            className={`mt-10 ${textAlignClass}`}
            innerHtml={blockContent.text}
            small
          ></TextBasic>
        )}
        {blockContent.buttons &&
          blockContent.buttons.map((button: any, index: number) => {
            return (
              <Button
                link={button.buttonLink}
                color1={cssVariables.colors[button.buttonColor1]}
                color2={cssVariables.colors[button.buttonColor2]}
                flip={blockContent.textAlign === "end"}
              ></Button>
            )
          })}
      </div>
    </div>
  )
}
