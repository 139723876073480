import React, { useContext, useRef } from "react"
import {
  DEFAULT_BLOCKOPTIONS_DEFAULTS,
  getItemFromId,
} from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import {
  getAbsolutePositionStyle,
  getFlexPositionStyle,
} from "../../styles/util"
import H1 from "../atoms/H1"
import H3 from "../atoms/H3"

import { DataContext } from "../layout"
import useInteractiveShadow from "../../hooks/useInteractiveShadow"
import InteractiveShadow from "../molecules/InteractiveShadow"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"

type props = any

export default function HeroTextBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData, videoData } =
    useContext(DataContext)

  const flexPositionStyle = getFlexPositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "60px"
  )

  const textAlignClass =
    blockContent.textHorizontalPos === "start"
      ? "text-left"
      : blockContent.textHorizontalPos === "center"
      ? "text-center"
      : "text-right"

  return (
    <div
      className={`w-full relative flex grayscale filter mx-auto overflow-hidden p-20 ${
        blockContent.fillScreen ? "h-screen" : ""
      }`}
      style={{ ...flexPositionStyle }}
      // style={{ paddingBottom: ratio * 100 + "%" }}
    >
      <InteractiveShadow
        enabled={blockContent.interactiveShadow}
        baseHeight={blockContent.baseHeight / 50}
        wobbleAmt={blockContent.wobbleAmt / 100}
      >
        <div>
          {blockContent.subtitleTop && (
            <H3
              color={
                thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).title
                  : "black"
              }
              className={`text-${blockContent.textHorizontalPos} overflow-y-visible font-titleAccent mt-2`}
            >
              {blockContent.subtitleTop}
            </H3>
          )}
          {blockContent.title && (
            <H1
              className={`font-titleAccent ${textAlignClass} mt-2 whitespace-nowrap`}
              color={
                thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).title
                  : "black"
              }
            >
              {blockContent.title}
            </H1>
          )}
          {blockContent.subtitle && (
            <H3
              color={
                thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).title
                  : "black"
              }
              className={`text-${blockContent.textHorizontalPos} overflow-y-visible font-titleAccent mt-2`}
            >
              {blockContent.subtitle}
            </H3>
          )}
        </div>
      </InteractiveShadow>
    </div>
  )
}

export const HeroTextBlock_Inputs = {
  key: "heroTextBlock",
  name: "Hero Text Block",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "line 1 Title",
          keyName: "subtitleTop",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Title Horizontal Position",
          keyName: "textHorizontalPos",
          defaultVal: "left",
        },
        {
          inputType: "radio",
          labelArr: ["Top", "Center", "Bottom"],
          valueArr: ["start", "center", "end"],
          label: "Title Vertical Position",
          keyName: "textVerticalPos",
          defaultVal: "center",
        },
      ],
    },
    { type: "group", inputGroupName: "shadowInputGroup" },
    {
      inputType: "checkbox",
      keyName: "fillScreen",
      label: "Always as high as screen",
    },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
