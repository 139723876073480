import React, { ReactNode } from "react"

type elementProps = {
  className?: string
  color?: string
  children?: ReactNode
  innerHtml?: string
  small?: boolean
  sizeClass?: string
}

export default function TextBasic({
  className,
  color,
  children,
  innerHtml,
  small,
  sizeClass,
}: elementProps) {
  return (
    <div
      style={{ color: color }}
      className={`tracking-wider leading-relaxed font-basic ${
        sizeClass
          ? sizeClass
          : small
          ? "text-sm md:text-md xl:text-lg"
          : "text-md md:text-lg xl:text-xl"
      }  m-0 select-none ${className}`}
      dangerouslySetInnerHTML={innerHtml ? { __html: innerHtml } : undefined}
    >
      {children}
    </div>
  )
}
