import React, { useContext, useState, useRef } from "react"
import { Link, Page } from "@rubendenbrok/bloqmatter-cms"
import BloqmatterLink from "../atoms/BloqmatterLink"
import { DataContext } from "../layout"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"
import AnimatedMenuOpenCloseIcon from "../atoms/AnimatedMenuOpenCloseIcon"
import H3 from "../atoms/H3"
import TextBasic from "../atoms/TextBasic"
import { Link as GatsbyLink } from "gatsby"

export default function MobileHeader() {
  const { headerData, footerData, thisPage, cssVariables } =
    useContext(DataContext)
  const [showMenu, setShowMenu] = useState(false)
  const [showMenuBar, setShowMenuBar] = useState(false)

  const showRef = useRef(false)

  const showMenuAtScrollPos = 150

  function handleScroll() {
    if (typeof window !== "undefined") {
      if (window.scrollY > showMenuAtScrollPos && !showRef.current) {
        showRef.current = true
        setShowMenuBar(true)
      }
      if (window.scrollY < showMenuAtScrollPos && showRef.current) {
        showRef.current = false
        setShowMenuBar(false)
      }
    }
  }

  useSafeWindowEventlistener("scroll", handleScroll)

  return (
    <>
      <div className="z-[9999] fixed top-0 right-4 block sm:hidden">
        <AnimatedMenuOpenCloseIcon
          isOpen={showMenu}
          onOpen={() => setShowMenu(true)}
          onClose={() => setShowMenu(false)}
          color={
            !showMenuBar && thisPage?.useTransparentHeader && !showMenu
              ? cssVariables.colors[headerData.transparentHeaderTextColor]
              : cssVariables.colors[headerData.textColor]
          }
        />
      </div>
      <div
        style={{
          backgroundColor:
            (!showMenuBar && thisPage?.useTransparentHeader) || showMenu
              ? cssVariables.colors[headerData.bgColor] + "00"
              : cssVariables.colors[headerData.bgColor] + "bb",
          color:
            !showMenuBar && thisPage?.useTransparentHeader
              ? cssVariables.colors[headerData.transparentHeaderTextColor]
              : cssVariables.colors[headerData.textColor],
        }}
        className="flex items-center px-4 py-3 top-0 left-0 fixed w-screen justify-between transition-colors duration-500 z-[999] backdrop-blur-md  sm:hidden"
      >
        <BloqmatterLink
          link={{ external: true, href: "/", targetId: "", text: "" }}
        >
          <TextBasic
            className={`transition-opacity duration-500 ${
              showMenuBar && !showMenu ? "opacity-100" : "opacity-0"
            }`}
          >
            {thisPage?.title}
          </TextBasic>
        </BloqmatterLink>
      </div>
      <header
        className="block sm:hidden w-screen fixed h-screen left-0 z-50 backdrop-blur-md"
        style={{
          backgroundColor: cssVariables.colors[headerData.bgColor] + "bb",
          color: cssVariables.colors[headerData.textColor],
          top: showMenu ? 0 : "-100vh",
          opacity: showMenu ? 1 : 0.5,
          pointerEvents: showMenu ? "auto" : "none",

          transition: "top 0.5s , opacity 0.3s",
        }}
      >
        <nav className="w-full h-screen px-8 flex flex-col items-start justify-between relative font-title">
          <div className="mt-16 flex flex-col justify-start items-start">
            {headerData.menuItems &&
              headerData.menuItems.map((item: any) => {
                if (
                  item.visibility === "always" ||
                  item.visibility === "mobile"
                ) {
                  return (
                    <MenuItem
                      key={item.id}
                      isDropdown={item.isDropdown}
                      childMenuItems={item.childMenuItems}
                      link={item.link}
                      accentColor={headerData.accentColor}
                      cssVariables={cssVariables}
                      setShowMenu={setShowMenu}
                    />
                  )
                }
              })}
            <GatsbyLink to={"/#contact"}>
              <div
                onClick={() => setShowMenu(false)}
                className="my-4 text-2xl"
                //style={{ textShadow: "3px 3px 2px black" }}
              >
                Contact
              </div>
            </GatsbyLink>
          </div>

          <TextBasic
            className="lg:text-right text-left mb-16 block"
            innerHtml={footerData.footerText}
          ></TextBasic>
        </nav>
      </header>
    </>
  )
}

type menuItemProps = {
  isDropdown: boolean
  childMenuItems: any[]
  link: Link
  accentColor: number
  setShowMenu: Function
  cssVariables: { [x: string]: string }
}

function MenuItem({
  isDropdown,
  childMenuItems,
  link,
  accentColor,
  cssVariables,
  setShowMenu,
}: menuItemProps) {
  return (
    <>
      <BloqmatterLink link={link}>
        <div onClick={() => setShowMenu(false)} className=" my-4 text-2xl">
          {link.text}
        </div>
      </BloqmatterLink>
      {/* DROPDOWN */}
      {isDropdown && Array.isArray(childMenuItems) && (
        <div className="">
          {childMenuItems.map(item => {
            if (item.visibility === "always" || item.visibility === "mobile") {
              return (
                <BloqmatterLink key={item.id} link={item.link}>
                  <MenuChildItem
                    link={item.link}
                    cssVariables={cssVariables}
                    childMenuItems={childMenuItems}
                    isDropdown={isDropdown}
                    accentColor={accentColor}
                  />
                </BloqmatterLink>
              )
            }
          })}
        </div>
      )}
    </>
  )
}
type MenuLinkProps = {
  link: Link
  cssVariables: { [x: string]: string }
  isDropdown: boolean
  childMenuItems: any[]
  accentColor: number
}

function MenuChildItem({ link, cssVariables, accentColor }: MenuLinkProps) {
  return (
    <div className="ml-8 px-2 py-2 relative block text-lg ">{link.text}</div>
  )
}
