import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import getSafeWindow from "../../functions/getSafeWindow"
import useFrame from "../../hooks/useFrame"
import useInteractiveShadow from "../../hooks/useInteractiveShadow"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"

type InteractiveShadowProps = {
  baseHeight: number
  wobbleAmt: number
  enabled?: boolean
  className?: string
  children: ReactNode
}

export default function InteractiveShadow({
  baseHeight,
  wobbleAmt,
  className,
  enabled,
  children,
}: InteractiveShadowProps) {
  if (!enabled) return <>{children}</>

  const [pos, setPos] = useState({ x: 0, y: 0 })
  const [height, setHeight] = useState(baseHeight)

  const nodeRef = useRef<HTMLDivElement | null>(null)
  const mousePosRef = useRef({ x: 0, y: 0 })
  const DIST_MULTIPLIER = 0.05
  const MIN_BLUR = 3

  useFrame(() => {
    if (nodeRef.current) {
      const newHeight =
        baseHeight + Math.sin(performance.now() / 1000) * wobbleAmt + wobbleAmt

      const elementX =
        nodeRef.current.getBoundingClientRect().left +
        nodeRef.current.getBoundingClientRect().width / 2
      const elementY =
        nodeRef.current.getBoundingClientRect().top +
        nodeRef.current.getBoundingClientRect().height / 2

      setPos({
        x: (elementX - mousePosRef.current.x) * DIST_MULTIPLIER * newHeight,
        y: (elementY - mousePosRef.current.y) * DIST_MULTIPLIER * newHeight,
      })
      setHeight(newHeight)
    }
  }, [baseHeight, wobbleAmt])

  useEffect(() => {
    const safeWindow = getSafeWindow()
    if (safeWindow) {
      mousePosRef.current = {
        x: safeWindow.innerWidth / 2,
        y: safeWindow.innerHeight / 2,
      }
    }
  }, [])

  function handleMouseMove(e: MouseEvent) {
    mousePosRef.current = { x: e.clientX, y: e.clientY }
  }
  useSafeWindowEventlistener("mousemove", handleMouseMove)

  return (
    <div ref={nodeRef} className={`relative ${className}`}>
      <div
        className="relative z-50"
        style={{ transform: `scale(${height * 0.07 + 1})` }}
      >
        {children}
      </div>
      <div
        className="absolute left-0 top-0 z-0"
        style={{
          opacity: 0.6,
          transform: `translate(${pos.x}px, ${pos.y}px) scale(${
            height * 0.15 + 1.0
          })`,
          filter: `brightness(0) blur(${
            Math.sqrt(Math.pow(pos.x, 2) + Math.pow(pos.y, 2)) / 10 + MIN_BLUR
          }px)`,
        }}
      >
        {children}
      </div>
    </div>
  )
}
