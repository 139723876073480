export function getVisibilityFromBlockContent(blockContent: any) {
  if (blockContent.visibleDesktop && blockContent.visibleMobile) return ""
  if (blockContent.visibleDesktop) return "hidden sm:block "
  if (blockContent.visibleMobile) return "block sm:hidden "
  return "hidden"
}

type Positions = "start" | "center" | "end"

export function getAbsolutePositionStyle(
  hPos: Positions,
  vPos: Positions,
  padding: string
) {
  const transX = hPos === "center" ? "-50%" : "0%"
  const transY = vPos === "center" ? "-50%" : "0%"
  const left = hPos === "end" ? "" : hPos == "start" ? padding : "50%"
  const right = hPos === "end" ? padding : ""
  const top = vPos === "end" ? "" : vPos == "start" ? padding : "50%"
  const bottom = vPos === "end" ? padding : ""

  return {
    left: left,
    right: right,
    top: top,
    bottom: bottom,
    transform: `translate(${transX}, ${transY})`,
  }
}

export function getFlexPositionStyle(
  hPos: Positions,
  vPos: Positions,
  padding: string
) {
  const x =
    hPos === "center" ? "center" : hPos === "start" ? "flex-start" : "flex-end"
  const y = vPos

  return {
    justifyContent: x,
    alignItems: y,
  }
}
