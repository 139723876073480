import React, { useContext, useState, useRef } from "react"
import { Link, Page } from "@rubendenbrok/bloqmatter-cms"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"
import BloqmatterLink from "../atoms/BloqmatterLink"
import { DataContext } from "../layout"
import { Link as GatsbyLink } from "gatsby"

const navbarHeight = "45px"

export default function DesktopHeader() {
  const { thisPage, allPages, cssVariables, headerData } =
    useContext(DataContext)
  const [showMenu, setShowMenu] = useState(false)
  const showRef = useRef(false)

  const showMenuAtScrollPos = 300

  function handleScroll() {
    if (typeof window !== "undefined") {
      if (window.scrollY > showMenuAtScrollPos && !showRef.current) {
        showRef.current = true
        setShowMenu(true)
      }
      if (window.scrollY < showMenuAtScrollPos && showRef.current) {
        showRef.current = false
        setShowMenu(false)
      }
    }
  }

  useSafeWindowEventlistener("scroll", handleScroll)

  return (
    <>
      <header
        className={`w-full sm:inline-block hidden fixed overflow-y-visible`}
        style={{
          backgroundColor:
            !showMenu && thisPage?.useTransparentHeader
              ? cssVariables.colors[headerData.bgColor] + "00"
              : cssVariables.colors[headerData.bgColor] + "ff",
          color:
            !showMenu && thisPage?.useTransparentHeader
              ? cssVariables.colors[headerData.transparentHeaderTextColor]
              : cssVariables.colors[headerData.textColor],
          zIndex: 999,
          top: "0px",
          transition: "background-color 0.9s, color 0.9s",
        }}
      >
        <nav
          className={`w-full max-w-[1400px] mx-auto flex items-center justify-around relative `}
          style={{ height: navbarHeight }}
        >
          <div
            className="absolute h-full w-screen left-1/2 -translate-x-1/2 top-0"
            style={{
              boxShadow: "0px 0px 5px #00000037",
              opacity: !showMenu && thisPage?.useTransparentHeader ? 0 : 1,
              transition: "opacity 0.9s",
            }}
          ></div>

          {headerData.logoImage && (
            <img
              src={headerData.logoImage.urls.fallback.xs}
              className="w-20 p-2"
            />
          )}
          {headerData.menuItems &&
            headerData.menuItems.map((item: any) => {
              if (
                item.visibility === "always" ||
                item.visibility === "desktop"
              ) {
                return (
                  <MenuItem
                    key={item.id}
                    isDropdown={item.isDropdown}
                    allPages={allPages}
                    childMenuItems={item.childMenuItems}
                    link={item.link}
                    accentColor={headerData.accentColor}
                    bgColor={headerData.bgColor}
                    showMenu={showMenu}
                  />
                )
              }
            })}
          <GatsbyLink to={"/#contact"}>
            <div
              className=" py-4 h-full font-normal md:text-xl text-lg relative"
              //style={{ textShadow: "3px 3px 2px black" }}
            >
              Contact
              {/* DECORATION */}
            </div>
          </GatsbyLink>
        </nav>
      </header>
    </>
  )
}

type menuItemProps = {
  isDropdown: boolean
  childMenuItems: any[]
  link: Link
  allPages: Page[]
  accentColor: number
  bgColor: number
  showMenu?: boolean
}

function MenuItem({
  isDropdown,
  childMenuItems,
  link,
  allPages,
  accentColor,
  bgColor,
  showMenu,
}: menuItemProps) {
  const [hovered, setHovered] = useState(false)
  const { thisPage, cssVariables, headerData } = useContext(DataContext)

  return (
    <div
      className="relative z-50 h-full flex justify-center items-center overflow-visible"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        if (!Array.isArray(childMenuItems) || !isDropdown) {
          setHovered(false)
        }
      }}
    >
      {/* PARENT LINK */}
      <BloqmatterLink link={link}>
        <MenuParentItem
          link={link}
          hovered={hovered}
          setHovered={setHovered}
          childMenuItems={childMenuItems}
          isDropdown={isDropdown}
          accentColor={accentColor}
          showMenu={showMenu}
        />
      </BloqmatterLink>

      {/* DROPDOWN */}
      {hovered && isDropdown && Array.isArray(childMenuItems) && (
        <div
          onMouseLeave={() => setHovered(false)}
          className="absolute top-0 left-4"
        >
          <BloqmatterLink link={link}>
            <div style={{ height: navbarHeight }} className="w-full"></div>
          </BloqmatterLink>
          <div
            style={{
              // backdropFilter: "blur(5px) saturate(0%) invert(100%)",
              backgroundColor: cssVariables.colors[bgColor] + "ee",
            }}
          >
            {childMenuItems.map(item => {
              if (
                item.visibility === "always" ||
                item.visibility === "desktop"
              ) {
                return (
                  <BloqmatterLink key={link.text} link={item.link}>
                    <MenuChildItem
                      link={item.link}
                      hovered={hovered}
                      setHovered={setHovered}
                      childMenuItems={childMenuItems}
                      isDropdown={isDropdown}
                      accentColor={accentColor}
                    />
                  </BloqmatterLink>
                )
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

type MenuLinkProps = {
  link: Link
  hovered: boolean
  setHovered: Function
  isDropdown: boolean
  childMenuItems: any[]
  accentColor: number
  showMenu?: boolean
}

function MenuParentItem({
  link,
  hovered,
  setHovered,
  accentColor,
  showMenu,
}: MenuLinkProps) {
  const { thisPage, cssVariables, headerData } = useContext(DataContext)
  return (
    <div
      className="py-4 h-full font-normal md:text-xl sm:text-lg relative"
      //style={{ textShadow: "3px 3px 2px black" }}
    >
      {link.text}

      {/* DECORATION */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-4 h-px transition-all"
        style={{
          width: hovered ? "100px" : "30px",
          opacity: hovered ? 0.35 : 0,
          backgroundColor:
            !showMenu && thisPage?.useTransparentHeader
              ? cssVariables.colors[headerData.transparentHeaderTextColor]
              : cssVariables.colors[headerData.textColor],
        }}
      ></div>
    </div>
  )
}

function MenuChildItem({
  link,
  hovered,
  setHovered,
  accentColor,
}: MenuLinkProps) {
  const { thisPage, cssVariables, headerData } = useContext(DataContext)
  const [isHovered, setIsHovered] = useState(false)
  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="px-4 py-4 font-normal block relative "
        style={{ width: "max-content", textShadow: "3px 3px 2px black" }}
      >
        {link.text}
        {/* DECORATION */}
        <div
          className="absolute left-2 bottom-2 h-0.5 transition-all "
          style={{
            width: isHovered ? "120px" : "30px",
            opacity: isHovered ? 1 : 0,
            backgroundColor: cssVariables.colors[accentColor],
          }}
        ></div>
      </div>
    </>
  )
}

export const AddHeaderInputs = [
  {
    index: 1,
    input: {
      inputType: "color",
      label: "Transparent Header Text Color",
      keyName: "transparentHeaderTextColor",
    },
  },
]
